<script setup>
  import { filtrationData, filtrationResetData, handleFiltrationRadio, hasFilters, closeFiltration } from './filtrationFunc'
  import Pagination from '@/components/Pagination'
  import commodityItem from '@/views/index/compontent/commodity-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, nextTick, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const setLoading = inject('setLoading')

  const props = defineProps({
    queryParms: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const data = reactive({
    loading: false,
    discountsObj: [],
    list: [],
    commodityItemLength: 5,
    sortBy: '',
    categoryIdAndNameDto: {}
  })

  const paginationData = reactive({
    page: 1,
    pageSize: 20,
    total: 0
  })

  const sortOptions = [
    {
      name: 'Most Popular',
      value: 'Most Popular'
    },
    {
      name: 'Newest',
      value: 'Newest'
    },
    {
      name: 'Price Low to High',
      value: 'Price Low to High'
    },
    {
      name: 'Price High to Low',
      value: 'Price High to Low'
    }
  ]

  // 获取折扣活动列表
  const getDiscountsList = (id) => {
    data.loading = true
    data.commodityItemLength = 5
    api.product.getDiscountsList(id).then((res) => {
      data.discountsObj = res.data.discountInfoSimpleDto
      data.list = res.data.productDiscountDtoList || []
      data.loading = false
      console.log(res, res.data.productDiscountDtoList, '获取折扣活动列表')
    })
  }

  // 分页
  const handCurrenPage = (val) => {
    paginationData.page = val
    getList()
    JLTools.scrollTo(100)
  }

  const handleChangeSolt = () => {
    paginationData.page = 1
    paginationData.total = 0
    getList()
  }

  // 查询商品
  const getList = (type) => {
    setLoading({ type: 'list', status: true })
    data.commodityItemLength = filtrationData.isShow ? 4 : 5
    let query = {
      'executed.equals': 'true',
      size: paginationData.pageSize,
      page: paginationData.page - 1,
      'productType.equals': 'GENERAL',
      // include: ['{id,name,amount,lineAmount,supplier,redTag,coverImg,productImg,buyingPointInfo,productType,specs{stockCount},specialOffer}']
    }
    if (!JLTools.isNull(props.queryParms.keyword)) {
      query['name.contains'] = props.queryParms.keyword
    }
    if (!JLTools.isNull(props.queryParms.productIds)) {
      query['id.in'] = props.queryParms.productIds
    }
    if (!JLTools.isNull(props.queryParms.firstCategoryId)) {
      query['firstCategoryId.in'] = props.queryParms.firstCategoryId
    }
    if (!JLTools.isNull(props.queryParms.secondCategoryId) || !JLTools.isNull(filtrationData.category)) {
      query['secondCategoryId.in'] = filtrationData.category.map((item) => Number(item.id)).join(',') || props.queryParms.secondCategoryId
      // console.log(query['secondCategoryId.in'], '312312')
    }
    if (!JLTools.isNull(props.queryParms.thirdCategoryId) || !JLTools.isNull(filtrationData.typeObj.type)) {
      query['thirdCategoryId.in'] = filtrationData.typeObj.type || props.queryParms.thirdCategoryId
    }
    if (!JLTools.isNull(data.sortBy)) {
      // 排序
      query['sortFiled.equals'] = data.sortBy
    }
    if (!JLTools.isNull(filtrationData.productFeatures)) {
      query['buyingPointInfo.in'] = filtrationData.productFeatures // 功能特征
    }
    if (!JLTools.isNull(filtrationData.popularBrands)) {
      query['brandName.in'] = filtrationData.popularBrands // 品牌
    }
    if (!JLTools.isNull(filtrationData.priceRangeObj.greaterThanOrEqual)) {
      // 价格
      query['amount.greaterThan'] = filtrationData.priceRangeObj.greaterThanOrEqual
    }
    if (!JLTools.isNull(filtrationData.priceRangeObj.lessThanOrEqual)) {
      //价格
      query['amount.lessThan'] = filtrationData.priceRangeObj.lessThanOrEqual
    }
    if (filtrationData.priceRangeObj && filtrationData.priceRangeObj.priceRange) {
      query['amount.greaterThan'] = filtrationData.priceRangeObj.item.minPrice
      query['amount.lessThan'] = filtrationData.priceRangeObj.item.maxPrice
    }
    // console.log(query, '查询条件')
    api.product
      .getProductsList(query)
      .then((res) => {
        console.log('商品列表结果', res)
        data.categoryIdAndNameDto = res.data.categoryIdAndNameDto
        data.list = res.data.page.content
        paginationData.total = res.data.page.totalElements
        // data.list = res.data
        // paginationData.total = res.headers['x-total-count']
        setLoading({ type: 'list', status: false })
      })
      .catch(() => {
        setLoading({ type: 'list', status: false })
      })
  }

  watch(
    () => HsystemStore.breadcrumbList,
    (newVal, oldVal) => {
      //   init()
    }
  )

  defineExpose({
    getList,
    handleChangeSolt,
    handCurrenPage
  })
</script>

<template>
  <div class="list-box mb20">
    <div>
      <div class="rowBC select-box" v-if="paginationData.total">
        <div class="rowSC mb20">
          <span class="select-label">Sort by:</span>
          <el-select v-model="data.sortBy" placeholder="Select" style="width: 276px" @change="handleChangeSolt">
            <el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="pro-main-breadcrumb rowSC" v-if="data.categoryIdAndNameDto?.name">
          <span>{{ $t('Home') }}</span>
          <img src="~img/down_icon.svg" />
          <b>{{ data.categoryIdAndNameDto.name }}</b>
        </div>
      </div>
      <div class="total-num" v-if="paginationData.total">
        <span class="font-weight-700">{{ paginationData.total }}</span> Results found.
      </div>
      <div class="clear-box" data-remark="过滤列表清除事件" v-if="hasFilters()">
        <div class="item rowCC" v-for="item in filtrationData.category" :key="item.id">
          <span>{{ item.name }}</span>
          <i class="iconfont icon-guanbi guanbi-icon" @click="closeFiltration(item)"></i>
        </div>
        <div class="item rowCC" v-if="filtrationData.typeObj.type">
          <span>{{ filtrationData.typeObj.item.name }}</span>
          <i class="iconfont icon-guanbi guanbi-icon" @click="closeFiltration(filtrationData.typeObj.item)"></i>
        </div>
        <div class="item rowCC" v-for="item in filtrationData.productFeatures" :key="item.id">
          <span>{{ item.name }}</span>
          <i class="iconfont icon-guanbi guanbi-icon" @click="closeFiltration(item)"></i>
        </div>
        <div class="item rowCC" v-if="filtrationData.priceRangeObj.priceRange && filtrationData.priceRangeObj.item">
          <span>{{ filtrationData.priceRangeObj.item.name }}</span>
          <i class="iconfont icon-guanbi guanbi-icon" @click="closeFiltration(filtrationData.priceRangeObj.item)"></i>
        </div>
        <div class="item rowCC" v-for="item in filtrationData.popularBrands" :key="item.id">
          <span>{{ item.name }}</span>
          <i class="iconfont icon-guanbi guanbi-icon" @click="closeFiltration(item)"></i>
        </div>
        <div class="clear-all-box" @click="closeFiltration()">Clear All</div>
      </div>
    </div>

    <div v-if="data.list.length > 0">
      <div class="card-box-container" :style="{ gridTemplateColumns: `repeat(${data.commodityItemLength}, 1fr)` }">
        <div v-for="item of data.list" :key="item.id" class="card-box">
          <commodity-item :item="item" />
        </div>
      </div>

      <div class="rowCC mt20 w12">
        <Pagination
          :config="{ total: paginationData.total, pageSize: paginationData.pageSize, currentPage: paginationData.page }"
          v-if="paginationData.total > 0"
          @currentPage="handCurrenPage"
        ></Pagination>
      </div>
    </div>

    <template v-else>
      <el-empty :image="require('@/assets/imgs/product/pro-none.png')" :description="$t('We are sorry, We cannot find any product for your searched category')" image-size="400" />
    </template>
  </div>
</template>

<style lang="less" scoped>
  :deep(.el-select .el-input) {
    height: 32px;
  }
  :deep(.el-input__wrapper) {
    border-radius: 4px;
  }
  .list-box {
    width: 100%;
    min-height: 400px;
    .total-num {
      margin: 16px auto;
      font-size: 14px;
      .font-weight-700 {
        font-weight: 700;
      }
    }
    .select-box {
      flex-direction: row-reverse;
      .select-label {
        margin-right: 22px;
        color: #303133;
        font-size: 14px;
      }
    }
    .card-box-container {
      width: 100%;
      display: grid;
      gap: 32px 16px;
      -webkit-box-pack: justify;
      justify-content: space-between;
    }
  }
  .pro-main-breadcrumb {
    width: fit-content;
    font-size: 13px;
    line-height: 30px;

    img {
      margin: 0 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
    b {
      cursor: pointer;
    }
  }
  .clear-box {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 12px 16px;
    margin-bottom: 12px;
    border-radius: 4px;
    background: #fbfbfb;
    .item {
      padding: 6px 12px;
      border-radius: 4px;
      border: 1px solid #cae3f4;
      background: #eef6fb;
      color: #53a4db;
      cursor: pointer;
      .guanbi-icon {
        margin-left: 4px;
        vertical-align: middle;
        /* margin-top: 2px; */
      }
    }
    .clear-all-box {
      padding: 6px 12px;
      cursor: pointer;
    }
  }
</style>
