<!-- 商品卡片 -->
<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'

  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    item: {
      // 商品信息
      type: Object
    },
    commodity: {
      // 是否展示取消收藏
      type: Object
    },
    isDiscountStatus: {
      // 是否是折扣状态
      type: Boolean,
      default: false
    }
  })

  const $emit = defineEmits(['cancelCollection'])

  const toDetail = (id) => {
    const { href } = proxy.$router.resolve({
      name: 'productMain',
      params: {
        id: id
      }
    })
    window.open(href, '_blank')
  }

  const getDiscountRate = () => {
    let discountRate = null
    if (props.item.amount && props.item.lineAmount && props.item.lineAmount > props.item.amount) {
      const percentage = 100 - (props.item.amount / props.item.lineAmount) * 100
      discountRate = percentage.toFixed(0) + '%  OFF'
    }
    return discountRate
  }

  // 处理收藏逻辑(
  const cancelCollection = () => {
    $emit('cancelCollection', props.commodity)
  }

  const getStarClass = (index) => {
    if (props.item.avgLevel >= index) {
      // 满星
      return 'icon-shixinwujiaoxing'
    } else if (props.item.avgLevel + 0.5 >= index) {
      // 半星
      return 'fa-star-half-alt'
    }
    // 未选中
    return 'icon-kongxinwujiaoxing'
  }
</script>

<template>
  <div class="commodity-item" @click="toDetail(item.id)">
    <div class="pro-img-box">
      <div class="img-box">
        <JLImg class="pro-img" :src="item.coverImg" />
      </div>
      <div class="hint-pos hint-text" v-if="0 && item.supplier">{{ item.supplier }}</div>
    </div>
    <div class="info-box">
      <div class="hint-text" v-if="item.percentage && props.isDiscountStatus">{{ item.percentage }}</div>
      <el-tooltip popper-class="tooltop-box" effect="dark" placement="top">
        <template #content>{{ item.name }}</template>
        <div class="title text_hide2">{{ item.name }}</div>
      </el-tooltip>
      <div class="amount-text" v-if="item.discountPrice && props.isDiscountStatus">{{ JLTools.forPrice(item.discountPrice) }}</div>
      <div class="amount-text"  v-else-if="item.amount">{{ JLTools.forPrice(item.amount) }}</div>
      <div class="pro-pic-x" v-if="item.lineAmount && item.lineAmount > item.amount">{{ JLTools.forPrice(item.lineAmount) }}</div>
      <div class="live-box" v-if="commodity">
        <button class="is-live" @click.stop="cancelCollection(item)">Remove</button>
      </div>
      <div class="fot-box rowBC">
        <div class="rating">
          <span v-for="n in 5" :key="n">
            <i class="iconfont wujiaoxing" :class="['icon-kongxinwujiaoxing', getStarClass(n)]"></i>
          </span>
          <span class="comment-text">({{ item.commentCount || 0 }})</span>
        </div>
        <!-- <div class="shop-ico"></div> -->
      </div>
    </div>
  </div>
</template>

<style>
  .tooltop-box {
    max-width: 300px;
    word-break: break-all;
  }
</style>
<style lang="less" scoped>
  .commodity-item {
    width: 100%;
    height: 100%;
    /* padding-bottom: 10px; */
    border-radius: 4px;
    /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.06); */
    overflow: hidden;
    cursor: pointer;
    .pro-img-box {
      width: 100%;
      position: relative;
      font-size: 0px;
      .img-box {
        min-width: 100%;
        min-height: 170px;
        background: #e6eef6;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
      }
      .pro-img {
        width: 100%;
      }
      .hint-pos {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
      }
    }
    .info-box {
      padding: 16px 12px;
      .hint-text {
        width: fit-content;
      }
      .title {
        width: 100%;
        margin-top: 8px;
        min-height: 42px;
        font-size: 16px;
        color: var(--mainBgColor);
      }
      .amount-text {
        margin-top: 4px;
        font-size: 18px;
        line-height: 28px;
        color: var(--Zcolor);
      }
      .pro-pic-x {
        /* margin-top: 4px; */
        color: #909399;
        font-size: 12px;
        line-height: 12px;
        text-decoration: line-through;
      }
      .text_hide2 {
        flex-grow: 1;
      }
    }

    .hint-text {
      padding: 2px 10px;
      font-size: 12px;
      border-radius: 4px;
      /* border: 1px solid #cae3f4; */
      /* background: #eef6fb; */
      background: #ff7a00;
      color: #e6eef6;
    }

    .live-box {
      margin-top: 5px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      .is-live {
        color: #fff;
        background-color: #ff0000;
        border-radius: 40px;
        padding: 6px 12px;
        cursor: pointer;
      }
    }
    &:hover {
      /* position: relative;
      z-index: 1;
      cursor: pointer; */
      z-index: 1;
      /* box-shadow: 0 0 15px #c1c1c1; */
      box-shadow: 0px 0px 12px 0px rgba(83, 164, 219, 0.12);
      transition: box-shadow 0.5s;
      .pro-img-box {
        .img-box {
          background: rgba(3, 3, 3, 0.2);
        }
      }
    }
  }

  .fot-box {
    margin-top: 4px;
    font-size: 14px;
    color: #606266;
    font-weight: 600;
    .rating {
      .wujiaoxing {
        font-size: 14px;
        margin-right: 1px;
      }

      .icon-shixinwujiaoxing {
        color: #f7ba2a;
      }
      .comment-text {
        color: #606266;
        font-family: 400;
      }
    }
    .shop-ico {
      width: 24px;
      height: 24px;
      background: url('https://cdn.pricoo.pk/02c1fb09.png') no-repeat;
      background-size: 100% 100%;
    }
  }
</style>
