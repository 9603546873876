import { ref, reactive, toRaw, isReactive } from 'vue'

const filtrationData = reactive({
  category: [], // 选中一级分类列表
  typeObj: {
    type: '', // 选中二级分类列表
    item: {}
  },
  productFeatures: [], // 选中产品特点
  priceRangeObj: {
    priceRange: '', // 选中价格范围
    greaterThanOrEqual: '', // 价格大于等于
    lessThanOrEqual: '' // 价格小于等于
  }, // 选中价格范围
  popularBrands: [], // 选中品牌
  selectedList: [],
  isUpdata: false, // 是否需要更新
  isShow: false // 是否显示
})

// 重置选项
const filtrationResetData = (type) => {
  if (type === 'category') {
    filtrationData.category = []
  } else if (type === 'type') {
    filtrationData.typeObj = {
      type: '', // 选中二级分类列表
      item: {}
    }
  } else if (type === 'productFeatures') {
    filtrationData.productFeatures = []
  } else if (type === 'priceRange') {
    filtrationData.priceRangeObj = {
      priceRange: '', // 选中价格范围
      greaterThanOrEqual: '', // 价格大于等于
      lessThanOrEqual: '' // 价格小于等于
    }
  } else if (type === 'popularBrands') {
    filtrationData.popularBrands = []
  } else {
    resetAll()
  }
}

const handleFiltrationRadio = (type, list) => {
  switch (type) {
    case 'type':
      list.find((item) => {
        if (item.id === filtrationData.typeObj.type) {
          filtrationData.typeObj.item = item
        }
      })
      console.log(filtrationData.typeObj, 'filtrationData.typeObj')
      break
    case 'priceRange':
      list.find((item) => {
        if (item.name === filtrationData.priceRangeObj.priceRange) {
          filtrationData.priceRangeObj.item = item
          // filtrationData.priceRangeObj.greaterThanOrEqual = item.minPrice
          // filtrationData.priceRangeObj.lessThanOrEqual = item.maxPrice
        }
      })
      console.log(filtrationData.priceRangeObj, 'filtrationData.typeObj')
      break
    case 'productFeatures':
      break
    default:
      break
  }
  console.log(filtrationData, 'filtrationData')
}

// 判定是否选中过滤
const hasFilters = () => {
  console.log(
    '22222222222',
    filtrationData.category.length ||
      filtrationData.typeObj.type ||
      filtrationData.productFeatures.length ||
      filtrationData.priceRangeObj.priceRange ||
      filtrationData.popularBrands.length
  )
  return (
    filtrationData.category.length ||
    filtrationData.typeObj.type ||
    filtrationData.productFeatures.length ||
    filtrationData.priceRangeObj.priceRange ||
    filtrationData.popularBrands.length
  )
}

// 清除所有过滤
const resetAll = () => {
  filtrationData.category = []
  filtrationData.typeObj = {
    type: '',
    item: {}
  }
  filtrationData.productFeatures = []
  filtrationData.priceRangeObj = {
    priceRange: '',
    greaterThanOrEqual: '',
    lessThanOrEqual: ''
  }
  filtrationData.popularBrands = []
}

// 清除单选项
const closeFiltration = (item) => {
  if (item) {
    // 移除特定的过滤项
    if (filtrationData.category.includes(item)) {
      filtrationData.category = filtrationData.category.filter((i) => i !== item)
    } else if (filtrationData.productFeatures.includes(item)) {
      filtrationData.productFeatures = filtrationData.productFeatures.filter((i) => i !== item)
    } else if (filtrationData.popularBrands.includes(item)) {
      filtrationData.popularBrands = filtrationData.popularBrands.filter((i) => i !== item)
    } else if (item === filtrationData.typeObj.item) {
      filtrationData.typeObj.type = null // 清空类型
    } else if (item === filtrationData.priceRangeObj.item) {
      filtrationData.priceRangeObj.priceRange = null // 清空价格范围
    }
  } else {
    resetAll()
  }
  filtrationData.isUpdata = true
}

export { filtrationData, filtrationResetData, handleFiltrationRadio, hasFilters, closeFiltration }
